import React, { useState } from "react";

const AFFILIATE_LINK = "https://amzn.to/3bhEX4X";
const LEFT_SIDE = "left";
const RIGHT_SIDE = "right";
const AIRPODS_CONFIG = {
  [LEFT_SIDE]: {
    src: "./airpod_left.png",
    alt: "left airpod",
    tabIndex: 1
  },
  [RIGHT_SIDE]: {
    src: "./airpod_right.png",
    alt: "right airpod",
    tabIndex: 2
  }
};

const Airpod = ({ side, onClick, active }) => {
  return (
    <img
      src={AIRPODS_CONFIG[side].src}
      alt={AIRPODS_CONFIG[side].alt}
      tabIndex={AIRPODS_CONFIG[side].tabIndex}
      onClick={onClick}
      className={active ? "airpod shaking" : "airpod"}
    />
  );
};

const App = () => {
  const [leftSoundActive, setLeftSoundActive] = useState(false);
  const [rightSoundActive, setRightSoundActive] = useState(false);
  const [stereoSoundActive, setStereoSoundActive] = useState(false);

  const onClickLeft = () => {
    if (stereoSoundActive) {
      setStereoSoundActive(false);
    } else {
      setRightSoundActive(false);
      setLeftSoundActive(!leftSoundActive);
    }
  };

  const playRight = () => {
    if (stereoSoundActive) {
      setStereoSoundActive(false);
    } else {
      setLeftSoundActive(false);
      setRightSoundActive(!rightSoundActive);
    }
  };

  const playStereo = () => {
    setRightSoundActive(false);
    setLeftSoundActive(false);
    setStereoSoundActive(!stereoSoundActive);
  };

  return (
    <main className="container">
      <h1>Check your AirPods</h1>
      <p>
        Tap on the AirPod you want to check, tap again to stop the music
      </p>
      <Airpod
        side={LEFT_SIDE}
        onClick={onClickLeft}
        active={leftSoundActive || stereoSoundActive}
      />
      <Airpod
        side={RIGHT_SIDE}
        onClick={playRight}
        active={rightSoundActive || stereoSoundActive}
      />
      <div>
        <button
          className="stereo-button"
          onClick={playStereo}
          aria-live="polite"
        >
          {stereoSoundActive ? "Stop music" : "Play both ►"}
        </button>
      </div>
      <footer>
        <p className="no-margin">Need new AirPods?</p>
        <a href={AFFILIATE_LINK} target="_blank" rel="noopener noreferrer">
          Get a new pair of AirPods here >
        </a>
      </footer>
      {leftSoundActive && (
        <audio autoPlay loop data-testid="left-audio">
          <source src="./left.mp3" type="audio/mpeg" />
        </audio>
      )}
      {rightSoundActive && (
        <audio autoPlay loop data-testid="right-audio">
          <source src="./right.mp3" type="audio/mpeg" />
        </audio>
      )}

      {stereoSoundActive && (
        <audio autoPlay loop data-testid="stereo-audio">
          <source src="./stereo.mp3" type="audio/mpeg" />
        </audio>
      )}
    </main>
  );
};

export default App;
